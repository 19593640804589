.jb-brd {
   
   #intro-sec {
      padding-bottom: 30px;
   }
    
    .prj-submit-bdy {
        @media (min-width: $screen-sm) {  
            margin-top: -30px;
        }
    }

    .ld-mre-pst {
        padding-top: 30px;
        font-size: 20px!important;
    }

    .intr-shcs {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
    
   .intr-shcs {
      &:hover {
         background-color: transparent;
      }
   }
    
   .prj-submit-bdy {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 30px;
      padding-right: 30px;
      
      p {
         margin-left: 0;
         margin-right: 0;
      }
      
      @media (min-width: $screen-sm){
         margin-left: 0;
         margin-right: 0;
      }
   }
}

.jb-ovrvw-fx {
   @media (min-width: $screen-sm){
      padding-bottom: 60px !important;
   }
}

.jb-pst {
   .row {
      @media (min-width: $screen-sm){
         background-color: #f4f4f4;
         margin-left: 0;
         margin-right: 0;
      }
   }
}

.jb-pst-odd {
    .row {
        background-color: #f4f4f4;

        @media (min-width: $screen-sm){
           background-color: transparent;
        }
    }
}



.jb-pst {
    position: relative;
    .jb-pst-hd {
         padding-top: 25px;
         padding-bottom: 30px;

         @media (max-width: $screen-sm){
            padding-left: 20px;
            padding-right: 20px;
         }

         @media (min-width: $screen-sm){
            padding-top: 30px;
         }

         .row .col-sm-3 {
            @media(min-width: $screen-sm){
               padding-left: 30px;
            }
         }

        .jb-cmp-lgo-wrp {
            width: 154px;

            @media (max-width: $screen-sm){
               width: auto;
               max-width: 154px;
            }
        }

        .comp-det {

            font-weight: bold;
            padding-bottom: 25px;

            @media (max-width: $screen-sm){
                padding-bottom: 0;
            }
            .comp-nme {
                letter-spacing: 1px;
                font-size: 14px;
            }
            .comp-jb {
                line-height: 30px;
                font-size: 24px;
                font-weight: bold;

                @media (max-width: $screen-sm){
                    margin-bottom: 4px;
                }

                @media (min-width: $screen-sm) {
                    line-height: 0;
                }

                
            }


            .dl-jb-desc {
                font-size: 14px;
                font-weight: normal;
                padding-top: 10px;
                margin-bottom: -10px;
            }

        }

        .jb-dt {
            letter-spacing: 1px;
            font-size: 14px;
            @media (min-width: $screen-sm) {
                margin-right: 30px;
                text-align: right;
            }
        }
    }


    .jb-pst-det {
        @media (min-width: $screen-sm) {
            padding-bottom: 0;
        }

        font-size: 14px; 
        span {
            letter-spacing: 2px;
            font-weight: 700;
            font-size: 12px; 
        }

        .jb-pst-det-cntnt {
            padding-top: 20px;
            @media (min-width: $screen-sm) {
                padding-top: 30px;
            }
        }

    }

    .clsd-notif {
        width: 0px;
        height: 0px;
        border-left: 100px solid transparent;
        border-bottom: 100px solid #000;
        position: absolute;
        bottom: 0;
        right: 0;

        .clsd-notif-txt {
            color: #fff;
            font-size: 16px;
            position: absolute;
            right: 4px;
            top: 55px;
            transform: rotate(-45deg);
        }
    }
}

.coming-soon-content {
   margin-bottom: 30px;
}

.btn-jb-shw-mr-wrp {
   margin-bottom: 40px;
}