$primary-bg: #fff;
$secondary-bg: #f4f4f4;
$text-grey: #828282;
$font-color: #1F1F1F;

html,
body {
   margin:0;
   padding:0;
   height:100%;
   font-family: "Myriad Pro", serif;
   font-size: 20px;
   color: $font-color;
}

#ins-mn-cntr {
   font-family: "Myriad Pro";
   min-height:100%;
   position:relative;
}

#ins-hdr-cntr {
   font-family: "Myriad Pro";
   padding:10px;
}

#ins-mn-bdy-cntr {
   font-family: "Myriad Pro";
   padding-bottom: 541px;    /* Height of the footer */
   padding-top: 116px;
   
   @media (max-width: 320px) {
      padding-top: 85px;
   }
   
   @media (min-width: 320px) and (max-width: 420px) {
      padding-top: 98px;
   }
   
   @media (max-width: $screen-sm-min) {
      transition: padding-bottom 0.5s;
   }
   
   @media (min-width: 380px){
      padding-bottom: 500px;   /* Height of the footer */
   }
   
   /*@media (min-width: $screen-sm) {
      padding-bottom: 298px;
   }

   @media (min-width: $screen-sm) and (max-width: 850px){
      padding-bottom: 442px;
   }*/
   
   @media (min-width: $screen-sm-min) {
      padding-bottom: 250px;
      //padding-top: 119px;
      padding-top: 423px;
   }
   
   @media (min-width: $screen-sm-min) and (max-width: 851px) {
      padding-bottom: 442px;
   }
   
   @media (min-width: 851px) and (max-width: 1150px) {
      padding-bottom: 298px;
   }
   
   @media (min-width: 1150px) {
      padding-bottom: 250px;
   }
   
   &.partner-page {
      
      @media (min-width: 380px) and (max-width: 401px) {
         padding-bottom: 541px;   /* Height of the footer */
      }
      
   }
}

#ins-ftr-cntr {
   font-family: "Myriad Pro";
   //padding:10px;
   position:absolute;
   left: 0;
   bottom:0;
   min-height: 541px;   /* Height of the footer */
   width:100%;
   overflow: hidden;
   
   
   @media (min-width: 380px){
      min-height: 500px;   /* Height of the footer */
   }
   
   @media (min-width: $screen-sm-min) and (max-width: 851px) {
      min-height: 0;
      height: 442px;
   }
   
   @media (min-width: 851px) and (max-width: 1150px) {
      min-height: 0;
      height: 298px;
   }
   
   @media (min-width: 1150px) {
      min-height: 0;
      height: 250px;
   }
}

#ins-mn-bdy-cntr.common {
   padding-top: 116px;
   font-family: "Myriad Pro";
   //padding-bottom: 303px;    /* Height of the footer */

   @media (min-width: $screen-sm){
    //padding-top: 119px;
    padding-top: 423px;
      //padding-bottom: 140px;
   }

   @media (max-width: 420px){
      padding-top: 97px;
    }

    @media (max-width: 380px){
      padding-top: 85px;
    }
}

#ins-mn-bdy-cntr.sml-bnr {
   @media (min-width: $screen-sm){
      padding-top: 371px;
   }
}

#ins-mn-bdy-cntr.sml-bnr2 {
   @media (min-width: $screen-sm){
      padding-top: 371px;
   }
}

#ins-mn-bdy-cntr.sml-bnr3 {
   @media (min-width: $screen-sm){
      padding-top: 603px;
   }
}

#ins-mn-bdy-cntr.sml-bnr4 {
   @media (min-width: $screen-sm){
      padding-top: 823px;
   }
}

#ins-mn-bdy-cntr.sml-bnr5 {
   @media (min-width: $screen-sm){
      padding-top: 279px;
   }
}

#ins-mn-bdy-cntr.sml-bnr6 {
  @media (min-width: $screen-sm){
     padding-top: 681px;
  }
}


#ins-common-header {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 96px;
   
   @media (min-width: $screen-sm) {
      height: 119px;
   }
}

#ins-ftr-common.common {
   font-family: "Myriad Pro";
   background-color: #F4F4F4;
   //padding:10px;
   position: absolute;
   bottom:0;
   width:100%;
   height:303px;   /* Height of the footer */

   @media (min-width: $screen-sm){
     height: 140px;
   }
}

/* Override bootstrap container*/
.container {
	width: 100%;
	
	@media (min-width: $screen-lg) {
		max-width: 1200px;
	}
}
