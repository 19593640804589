

#showcaseModal {

  .modal-content {
    border-radius: 0;
  }

   .modal-body {
      padding-top: 0;
      padding-bottom: 45px;

      @media (min-width: $screen-sm){
         padding-left: 25px;
         padding-right: 25px;
         padding-bottom: 80px;
      }

      /*.row {
         margin-left: 0;
         margin-right: 0;
         @media (min-width: $screen-sm){
            margin-left: 40px;
            margin-right: 40px;
         }
      }*/
   }

   .close {
     opacity: 1;
     font-size: 24px;
   }

   .modal-header {
      border-bottom: 0;
   }

   .modal-title {
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 10px;
      padding-top: 5px;
      font-size: 20px;
   }

   .sub-title{
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 25px;
      
      @media (min-width: $screen-sm-min) {
         //margin-top: 10px;
      }
   }

   .objectives-section {
      //margin-bottom: 30px;
      @media (min-width: $screen-sm){
         //margin-bottom: 60px;
      }
   }

   .objectives-section ul {
      padding-left: 20px;
   }

   .mod-img-cont {
      min-height: 429px;      
      background-color: #f4f4f4;
      width: 100%;
      float: right;

      @media (min-width: $screen-sm){
         min-height: 543px;
         max-width: 357px;
      }
   }

   .mod-img-cont img{
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 30px;
      margin-bottom: 48px;

      @media (min-width: $screen-sm){
         padding: 10px 25px;
         margin-bottom: 40px;
      }

   }

   .mod-img-cont .project-det {
      padding-left: 33px;
      padding-right: 33px;

      @media (min-width: $screen-sm){
         padding-left: 26px;
         padding-right: 26px;
      }

      & h4 {
         font-weight: bold;
         margin-bottom: 3px;
      }

      p {
         margin-bottom: 26px;
         font-size: 18px;
      }
   }

   .mod-img-col {  
      @media (min-width: $screen-sm){
         padding-right: 40px;
      }
   }

   .mobile-slider {
      padding-left: 25px;
      padding-right: 25px;
   }

   .desktop-slider {
      padding-left: 5px;
      padding-right: 5px;
   }

   .gallery-title {
      margin-top: 30px;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 30px;

      @media (min-width: $screen-sm){
         margin-bottom: 30px;
         font-size: 24px;
         //margin-top: 0;
      }
   }

   .section-title {
      margin-bottom: 25px;
   }

   .slide-desc {
      font-size: 20px;
   }

   .modal-dialog {
      @media (min-width: 1200px){
         width: 1038px;
     }
   }
   
   #grd-mdl-sldr {
      margin: 0 !important;
   }
   
   .stndtwrk-slider-row {
      margin-right: -30px;
      
      @media (min-width: $screen-sm-min) {
         margin-right: -40px;
      }
      
   }
   
   .background-section {
      
      .sub-title {
         margin-top: 30px;
         
         @media (min-width: $screen-sm-min) {
            margin-top: 0;
         }
         
      }
      
      p {
         margin-bottom: 25px;
      }
      
   }
   
   .objectives-section-list {
      
      li {
         padding-left: 22px;
         margin-bottom: 20px;
      }
      
   }

   .second-container {
      padding-right: 15px;
   }

   #prevBtnShwcse {
      left: 15px;
   }
}