@font-face {
	font-family: 'Myriad Pro';
	src: url('../fonts/MyriadPro-Regular.eot');
	src: url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro-Regular.woff') format('woff'),
		url('../fonts/MyriadPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
         url('../fonts/lato-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoblack_italic';
    src: url('../fonts/lato-blackitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato-bold-webfont.woff2') format('woff2'),
         url('../fonts/lato-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold_italic';
    src: url('../fonts/lato-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latohairline';
    src: url('../fonts/lato-hairline-webfont.woff2') format('woff2'),
         url('../fonts/lato-hairline-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latohairline_italic';
    src: url('../fonts/lato-hairlineitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-hairlineitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoitalic';
    src: url('../fonts/lato-italic-webfont.woff2') format('woff2'),
         url('../fonts/lato-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latolight';
    src: url('../fonts/lato-light-webfont.woff2') format('woff2'),
         url('../fonts/lato-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latolight_italic';
    src: url('../fonts/lato-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}