.staff-section {
    padding-bottom: 43px;

    @media (min-width: $screen-sm){
        padding-bottom: 40px;
    }
    
    .slick-dots {
        position: initial;
    }
}


.mob-marg {
    margin-left: 15px;
    margin-right: 15px;
    @media (min-width: $screen-sm){
		margin: 0;
	}
}

.intro-panl {
    .container{
        padding-left:0;
    }
    .cont-sec {
        margin-left: 0;
        @media (min-width: $screen-sm) {
            width: 100%;
            margin: 0 auto;
        }
    }
}

.tchr-panl {
    padding-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
    @media (min-width: $screen-sm) {
        margin: 0;
    }
    overflow: hidden;

    &.show-all-expand {
        padding-bottom: 72px;
        @media (min-width: $screen-sm) {
            padding-bottom: 30px;
        }
    }

    .last-item {
        padding-bottom: 0!important;
        .tchr-inf-crd {
            bottom: 30px!important;
        }
    }
    .tchr-img-container {
        width: 100%;
        cursor: pointer;

        @media (max-width: $screen-sm) {
            height: 392px;
        }

        @media (min-width: $screen-sm) {
           // padding-bottom: 104px;
        }
        @media (min-width: 768px){
            margin-right: 0;
        }

        margin-bottom: 30px;
        &:hover .img-bckgrnd {
            box-shadow: 6px 5px 1px #cccccc;
        }
        &:hover .tchr-inf-crd {
            //box-shadow: 6px 5px 0px #e2c070;
            box-shadow: 6px 5px 0 #ccc;
            &.grdt-inf-crd {
               box-shadow: 6px 5px 0px #ccc;
            }
        }
        .img-bckgrnd {
            width: 100%!important;
            height: 301px;
            position: relative;
            background-position: center center;
            background-size: cover;

            @media (min-width: $screen-sm) {
                width: 94%!important;
                height: 420px;
            }
        }
        .tchr-inf-crd {
            width: 259px;
            //height: 143px;
            background-color: #fff;
            box-shadow: 6px 5px 0 #f4f4f4;
            position: absolute;
            z-index: 1;
            bottom: 30px;
            right: 35px;
            @media (min-width: $screen-sm) {
                //bottom: 134px;
                bottom: 30px;
                height: 173px;
                right: 20px;
            }
            .tchr-holder {
                width: 76%;
                margin: 0 auto;
                padding-bottom: 10px;
                .thcr-nme {
                    h3 {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 30px;
                    }
                }
                .tchr-pst {
                    font-size: 16px;
                }
            }
        }
    }
}

.see-all {
    text-align: right;
    padding-bottom: 35px;
    margin-right: 20px;
    @media (min-width: $screen-sm) {
        margin-right: 0;
    }
    .see-all-link {
        color: inherit;
        display: inline-block;
        text-decoration: underline;
    }
}



.stff-lctr {
    //padding-bottom: 84px;
    padding-bottom: 0;
    @media (min-width: 480px) {
        padding-bottom: 0;
    }


    .sldr-img-wrpr {
        height: 194px;
        //width: 286px;
    }



    .img-ovrd {
        width: 100%!important;
    }

    .slide-desc {
        font-style: italic;
        width: 286px!important;
    }

    .next-slide-btn, .prev-slide-btn {
        min-width: 30px!important;
        min-height: 48px!important;
        top: 35%;
    }

    .next-slide-btn {
        right: 20px;
    }

    .prev-slide-btn {
        z-index: 1;
        left: 20px;
    }

}
.vstng-lctrs {

    .cont-sec {
        padding-bottom: 44px;
        margin-left: 0;
    }

    #lctr-pnl {
        padding-bottom: 120px;
        @media (max-width: $screen-md) {
            width: 80%;
            margin: 0 auto;
        }
    }

    .lg-bdy {
        padding-top: 50px;
        margin: 0!important;
        .read-more {
            color: #000;
            text-decoration: underline;
        }
    }
}


.lctr-itm {
    width: 100%;
    padding-bottom: 20px;
}

.bse-img-info {
    position: absolute;
    top: -18px;
    left: -15px;
    @media (min-width: $screen-sm) {
        left: 0px;
    }

    // left: -15px;
    max-width: 88%;
    max-height: 100%;
    visibility: hidden;
    &:hover {
        visibility: visible;
    }
    .lctr-info-det {
        width: 80%;
        margin-top: 20px;
        margin-left: 20px;
        font-size: 18px;
        color: #fff;
        h3 {
            font-weight: bold;
        }
    }
}

.bse-img {
    width: 100%;
    //height: 100%;
    @media (min-width: $screen-sm) {
        //height: 100%;
    }
    .img-bckgrnd {
        width: 100%;
        height: auto;
        background-size: cover;
         background-repeat: no-repeat;
        @media (min-width: $screen-sm) {
            height: 326px;
        }

        @media (min-width: $screen-md) {
            height: 255px;
      }
    }
    &:hover {
        .bse-img-info {
            visibility: visible;
        }

        img, .img-bckgrnd {
            -webkit-filter:brightness(70%);
            -moz-filter:brightness(70%);
            filter: brightness(70%);
        }
    }
}

@media (min-width: $screen-sm) {
    #prt-img-1 {
        height: 48.16%!important;
    }
    
    
    #prt-img-2 {
        height: 57.13%!important;
    }
    
    
    #prt-img-3 {
        height: 49.14%!important;
    }
    
    #prt-img-4 {
        height: 49.14%!important;
    }
    
    #prt-img-5 {
        height: 39.93%!important;
    }
}



@media (min-width: 768px) {
    .modal-xl {
        max-width:1200px;
        width: 87%;
    }
}


#ins-mdl-cntnt {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    max-width: 1038px;

    margin: 0 auto;
    
    #ins-mdl-hdr {
        padding: 0;
        padding-top: 15px;
        padding-bottom: 10px;
        #ins-mdl-title {
            font-size: 20px;
            margin-top: 4px;
        }

        #ins-mdl-cls-btn {
            font-size: 24px;
            opacity: 1;
        }
    }
    // end header

    //body
    .ins-mdl-bdy {
        padding-top: 15px;
        @media (min-width: 768px) {
            padding-top: 100px;        
            margin: 0 36px;
        }

        #insp-brd {
            padding-bottom: 30px;
            padding-top: 50px;
            @media (min-width: $screen-sm) {
                padding-top: 0;
                padding-bottom: 100px;
            }
        }


        #hd-mdl-sec {
            padding-bottom: 40px;
            @media (min-width: $screen-sm) {
                padding-bottom: 0;
            }
        }


        .ins-mdl-sec {
            margin: 0;
            width: 100%!important;

            h3 {
                font-size: 24px!important;
            }

            #stf-nme {
                font-size: 30px!important;
                
                &.stf-nme-hd-tle {
                     margin-top: 0px;
                     font-family: latoregular,serif;
                }
            }
        }

        .ins-mdl-bdy-sec {

            .read-more {
                margin-bottom: 28px;
            }
            .ins-tchr-cv {
                background-color: #1f1f1f;
                position: relative;

                @media (min-width: 768px) {
                    margin-top: 50px;
                }


                .tchr-cv-pic {
                    background-size: cover;
                    background-position: center center;
                    width: 169px;
                    height: 239px;
                    position: absolute;
                    left: 34px;
                    top: -40px;
                }

                .tchr-cv-inf {
                    color: #fff;
                    padding-top: 223px;
                    width: 86%;
                    margin: 0 34px;
                    font-size: 14px;
                    //padding-bottom: 35px;
                    h4 {
                        font-weight: bold;
                    }
                    h5 {
                        margin-top: 0;
                        margin-bottom: 3px;
                    }
                    ul {
                        margin-left: 0!important;
                        padding: 0;
                        padding-bottom: 22px;
                        margin-bottom: 0;
                        li {
                            list-style: none;
                        }

                        li a {
                            text-decoration: none;
                            color: #fff;

                            &:hover, &:focus, &active {
                                text-decoration: none;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .cont-body {
                margin-right: 0;
                font-size: 18px;
                @media (min-width: $screen-sm) {
                    //margin-right: 30px;
                }
                p {
                    padding-bottom: 30px;
                }
                .no-pd-btm {
                    padding-bottom: 15px;
                }
                ul {
                    padding-left: 20px;
                    li {
                        padding-left: 20px;
                        padding-bottom: 30px;
                    }
                }
            }

            .cont-head {
                font-size: 18px;
                h3 {
                    font-size: 30px;
                }
            }

            .grid {
               @media (min-width: $screen-sm){
                  margin-right: -16px;
               }
            }

            .grid-item {
               float: left;
               /* vertical gutter */
               margin-bottom: 16px;

               @media (min-width: $screen-sm){
                  margin-right: 16px;
               }

               @media (max-width: $screen-sm){
                   width: 100%;
                   margin-bottom: 20px;
               }
            }

            /* clearfix */
            .grid:after {
               content: '';
               display: block;
               clear: both;
            }
        }
   }
   
   .stf-itr-occ-lbl {
      
      @media (max-width: $screen-sm-min) {
         padding-bottom: 35px;
      }
      
   }
}

.btn-tchr-img-container {
   color: inherit;
   
   &:hover,
   &:active,
   &:visited {
      color: inherit;
   }
   
}